import React from 'react';
import styles from './Home.module.css';
import classNames from 'classnames';

let cn = classNames.bind(styles);


class Home extends React.Component {
  render() {

    // console.log(this.props);

    const homeClass = cn({
      [styles.text]: true,
      [styles.show]: this.props.active,
      [styles.fadeInLeft]: this.props.active
    });

    return (
      <div className={homeClass}>
        <h1>Веб-дизайн<br/>и Фронтенд-разработка</h1>
        <h2 className={styles.description}>Разработка сайтов и приложений<br/> на базе React.js</h2>
      </div>
    );
  }
}

export default Home;