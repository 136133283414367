import React from 'react';
import styles from './Menu.module.css';
import classNames from 'classnames';
import MenuButton from './MenuButton/MenuButton';
import InfoButton from './InfoButton/InfoButton';

let cn = classNames.bind(styles);


class Menu extends React.Component {
  render() {
    const switchTextBlock = this.props.switchTextBlock;
    const isOpenMenu = this.props.isOpenMenu;
    const isMainState = this.props.isMainState;
    const closeMenu = this.props.closeMenu;
    const header = this.props.langData;
    const openMenu = this.props.openMenu;

    // console.log("isOpenMenu:", isOpenMenu);

    const mainClass = cn({
      [styles.main]: true,
      [styles.showMain]: isOpenMenu,
      [styles.marginMain]: !isOpenMenu,
      [styles.hideMain]: !isOpenMenu,
      
    });

    return (
      <div className={styles.menu}>
        <InfoButton openMenu={openMenu} isOpenMenu={isOpenMenu} buttonText="ИНФА"/>
        <div className={mainClass}>
          <div className={styles.header}>
            <a className={styles.socialV} href="https://vk.com/pavelsalfetkin" target="_blank" rel="noopener noreferrer" title={header.vk}></a>
            <a className={styles.socialF} href="https://www.facebook.com/pavel.salfetkin" target="_blank" rel="noopener noreferrer" title={header.fb}></a>
            <a className={styles.socialG} href="https://github.com/pavelsalfetkin" target="_blank" rel="noopener noreferrer" title={header.github}></a>
            <a className={styles.socialI} href="https://www.instagram.com/toblackweb/" target="_blank" rel="noopener noreferrer" title={header.instagram}></a>
          </div>
          <div className={styles.list}>
            <MenuButton switchTextBlock={switchTextBlock} closeMenu={closeMenu} activeMenu="home" goTo="/" activeLine={isMainState === "home"} buttonText="Главная"/>
            <MenuButton switchTextBlock={switchTextBlock} closeMenu={closeMenu} activeMenu="about" goTo="/about" activeLine={isMainState === "about"} buttonText="Обо мне"/>
            <MenuButton switchTextBlock={switchTextBlock} closeMenu={closeMenu} activeMenu="work" goTo="/work" activeLine={isMainState === "work"} buttonText="Работы"/>
            <MenuButton switchTextBlock={switchTextBlock} closeMenu={closeMenu} activeMenu="contact" goTo="/contact" activeLine={isMainState === "contact"} buttonText="Контакты"/>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;