import React from 'react';
import styles from './Main.module.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from '../Pages/Home/Home';
import About from '../Pages/About/About';
import Work from '../Pages/Work/Work';
import Contact from '../Pages/Contact/Contact';


class Main extends React.Component {
  render() {
    return (
      <div className={styles.main}>
        <Route exact path='/' render={(props) => (<Home {...props} active={true}/>)}/>
        <Route path='/about' render={(props) => (<About {...props} active={true}/>)}/>
        <Route path='/work' render={(props) => (<Work {...props} active={true}/>)}/>
        <Route path='/contact' render={(props) => (<Contact {...props} active={true}/>)}/>
      </div>
    );
  }
}

export default Main;