import React from 'react';
import styles from './Contact.module.css';
import classNames from 'classnames';
import img from '../../img/contact_foto.jpg';

let cn = classNames.bind(styles);

class Contact extends React.Component {
  render() {

    // console.log(this.props);
    
    const contactClass = cn({
      [styles.contact]: true,
      [styles.show]: this.props.active,
      [styles.fadeInLeft]: this.props.active
    });

    return (
      <div className={contactClass}>
        <h1>Контакты</h1>
        <h2>Для связи почта, телефон<br/> или социальные сети</h2>

        <div className={styles.contactInfo}>
          <div className={styles.contactFirst}>
            <span className={styles.mail}>Почта:</span>
            <span className={styles.mailData}>pavelsalf@yandex.ru</span>
            <span className={styles.phone}>Телефон:</span>
            <span className={styles.phoneData}>+7 926 37 532 37</span>
            <span className={styles.time}>В какое время можно связаться:</span>
            <span className={styles.timeData}>10:00 &mdash; 20:00 (понедельник &mdash; пятница)</span>
          </div>
          <div className={styles.contactSecond}>
            <span className={styles.social}>Социальные сети:</span>
            <span className={styles.vk}>
              <a href="https://vk.com/pavelsalfetkin" target="_blank" rel="noopener noreferrer" title="ВКонтакте">ВКонтакте</a>
            </span>
            <span className={styles.fb}>
              <a href="https://www.facebook.com/pavel.salfetkin" target="_blank" rel="noopener noreferrer" title="Фейсбук">Фейсбук</a>
            </span>
            <span className={styles.telegram}>@pavelsalfetkin (Telegram)</span>
            <span className={styles.location}>Я нахожусь тут:</span>
            <span className={styles.locationData}>Россия, Москва</span>
          </div>
        </div>

        <img className={styles.image} src={img} width="400" height="320" alt="Moscow"/>
        <p>Готов принять участие в интересных проектах<br/> и работать в команде разработчиков, учиться новому<br/> и вносить свой вклад в развитие проектов.</p>
      </div>
    );
  }
}

export default Contact;