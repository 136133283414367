import React from 'react';
import styles from './WorkElem.module.css';
// import classNames from 'classnames';

// let cn = classNames.bind(styles);


class WorkElem extends React.Component {
  render() {
    const projectDescription = this.props.projectDescription;
    const projectName = this.props.projectName;
    const linkDataName = this.props.linkDataName;
    const image = this.props.image;

    return (
      <div className={styles.elem}>
        <img className={styles.image} src={image} width="400" height="320" alt={projectName}/>
        <span className={styles.description}>{projectDescription}</span>
        <span className={styles.title} data-link-name={linkDataName}>{projectName}</span>
      </div>
    );
  }
}

export default WorkElem;