import React from 'react';
import styles from './About.module.css';
import classNames from 'classnames';
import img from '../../img/IMG_5700.jpg';

// ../../img/pavelsalf_about_140х296.jpg

let cn = classNames.bind(styles);


class About extends React.Component {
  render() {

    console.log(this.props);
    
    const aboutClass = cn({
      [styles.about]: true,
      [styles.show]: this.props.active,
      [styles.fadeInLeft]: this.props.active
    });

    return (
      <div className={aboutClass}>
        <h1>Обо мне</h1>
        <h2>Фронтенд разработчик</h2>
        <p>Привет, меня зовут Павел, я занимаюсь веб-дизайном,<br/> разработкой сайтов и интерфейсов.</p>
        <p>Верстаю адаптивные странички на &nbsp;HTML&nbsp; и &nbsp;CSS,<br/> программирую на &nbsp;JavaScript&nbsp; и проектирую &nbsp;UX/UI.<br/> Учусь у топовых специалистов и слежу за технологиями.</p>
        <p>Готов принять участие в интересных проектах<br/> и работать в команде разработчиков, учиться новому<br/> и вносить свой вклад в развитие проектов.</p>
        <img className={styles.image} src={img} width="400" height="320" alt="I am"/>
        <p>Готов принять участие в интересных проектах<br/> и работать в команде разработчиков, учиться новому<br/> и вносить свой вклад в развитие проектов.</p>
      </div>
    );
  }
}

export default About;