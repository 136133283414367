import React from 'react';
import styles from './InfoButton.module.css';
import classNames from 'classnames';

let cn = classNames.bind(styles);

class InfoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
    };
  }

  mouseOver = (e) => {
    e.preventDefault();
    this.setState({
      isMouseOver: true,
    });
  }

  mouseOut = (e) => {
    e.preventDefault();
    this.setState({
      isMouseOver: false,
    });
  }

  render() {
    const openMenu = this.props.openMenu;
    const isOpenMenu = this.props.isOpenMenu;
    const buttonText = this.props.buttonText;
    const isMouseOver = this.state.isMouseOver;

    const lineClass = cn({
      [styles.buttonLine]: true,
      [styles.hideLine]: !isMouseOver,
      [styles.showLine]: isMouseOver,
      [styles.buttonLineOpen]: isOpenMenu,
    });

    const textClass = cn({
      [styles.buttonText]: true,
      [styles.buttonTextOpen]: isOpenMenu,
    });

    // console.log("isOpenMenu:", isOpenMenu);

    return (
      <div className={styles.button}>
        <div className={lineClass}></div>
        <span className={textClass} onClick={openMenu} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>{buttonText}</span>
      </div>
    );
  }
}

export default InfoButton;