import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Menu from './Components/Menu/Menu';
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      isMainState: "home",
      isLang: "rus",
      langData: null
    };
  }

  componentDidMount() {
    const isLang = this.state.isLang;
    this.loadLang(isLang);
  }

  switchLang = (isLang) => {
    // console.log(`lang switch to "${isLang}"!`);
    this.setState({
      isLang: isLang,
    });
    this.loadLang(isLang);
  }

  loadLang = (isLang) => {
    import(`./data/lang_${isLang}.json`)
      .then((data) => {
        // console.log(`lang "${isLang}" loaded!`);
        this.setState({
          langData: data.default,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  isMainStateSwitcher = (mainState) => {
    mainState === "home" && this.setState({ isMainState: "home" });
    mainState === "about" && this.setState({ isMainState: "about" });
    mainState === "work" && this.setState({ isMainState: "work" });
    mainState === "contact" && this.setState({ isMainState: "contact" });
  }

  openMenu = (e) => {
    e.preventDefault();
    this.setState({
      isOpenMenu: !this.state.isOpenMenu,
    });
  }

  closeMenu = (e) => {
    // e.preventDefault();
    // console.log("currentTarget:", e.currentTarget.dataset.closeMenu);
    // console.log("target:", e.target.dataset.closeMenu);
    const isMain = e.currentTarget.dataset.closeMenu;
    const isMenu = e.currentTarget.dataset.closeMenu;

    if (isMenu) {
      this.setState({ isOpenMenu: false });
    }
    if (isMain) {
      this.setState({ isOpenMenu: false });
    }
  }

  switchTextBlock = (e) => {
    e.preventDefault();
    // console.log("switchTextBlock-data:", e.currentTarget.dataset.menuName);
    const activeMenu = e.currentTarget.dataset.menuName;
    this.isMainStateSwitcher(activeMenu);
  }

  render() {
    const isMainState = this.state.isMainState;
    const isOpenMenu = this.state.isOpenMenu;
    const langData = this.state.langData;
    // console.log(langData);

    // console.log("isOpenMenu:", isOpenMenu);
    // console.log(isMainState);

    // console.log(this.state.isLang);

    return (
      <Router>
        <div className="app-container-out">
          {langData && <Menu langData={langData.header} openMenu={this.openMenu} closeMenu={this.closeMenu} switchTextBlock={this.switchTextBlock} isOpenMenu={isOpenMenu} isMainState={isMainState} buttonText="ИНФА"/>}
          <div className="app-container-in" onClick={this.closeMenu} data-close-menu={true}>
            {langData && <Header langData={langData.header} switchLang={this.switchLang}/>}
            {langData && <Main langData={langData.main} isMainStateSwitcher={this.isMainStateSwitcher} isMainState={isMainState} isOpenMenu={isOpenMenu} openMenu={this.openMenu}/>}
            {langData && <Footer langData={langData.footer}/>}
          </div>
        </div>
      </Router>
    );
  }
}

export default App;