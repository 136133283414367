import React from 'react';
import styles from './Footer.module.css';


class Footer extends React.Component {
  render() {
    const footer = this.props.langData;

    return (
      <div className={styles.footer}>
        {/* <div className={styles.footerLine}></div> */}
        <div className={styles.footerContainer}>
          <span className={styles.footerCopyright} dangerouslySetInnerHTML={{__html: footer.copyright}}></span>
          {/* <div className={styles.footerLang}>
            <span className={styles.footerLangEng}>{footer.lang}</span>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Footer;