import React from 'react';
import styles from './Work.module.css';
import classNames from 'classnames';
import TextWorkElem from './WorkElem/WorkElem';
import img from '../../img/contact_foto.jpg';

let cn = classNames.bind(styles);


class Work extends React.Component {
  render() {

    console.log(this.props);
    
    const workClass = cn({
      [styles.work]: true,
      [styles.show]: this.props.active,
      [styles.fadeInLeft]: this.props.active
    });

    return (
      <div className={workClass}>
        <h1>Работы</h1>
        <h2>Разработка, дизайн, демо проекты<br/>некоторые проекты в процессе разработки</h2>
        <div className={styles.workList}>
          <TextWorkElem image={img} projectDescription="Дизайн и разработка для мастерской" projectName="Concrete Laboratory" linkDataName="conc"/>
          <TextWorkElem image={img} projectDescription="Дизайн для баскетбольной организации" projectName="Meteor path" linkDataName="meteor"/>
          <TextWorkElem image={img} projectDescription="Дизайн и разработка сервиса заметок" projectName="To-do" linkDataName="todo"/>
          <TextWorkElem image={img} projectDescription="Дизайн и разработка сервиса заметок" projectName="To-do" linkDataName="todo"/>
          <TextWorkElem image={img} projectDescription="Дизайн и разработка сервиса заметок" projectName="To-do" linkDataName="todo"/>
        </div>
      </div>
    );
  }
}

export default Work;