import React from 'react';
import styles from './Header.module.css';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchToLang: ""
    };
  }

  componentDidMount() {
    const switchToLang = this.props.langData.switchToLang;
    this.setState({
      switchToLang: switchToLang,
    });
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    console.log("prevProps:", prevProps.langData.switchToLang);
    console.log("this.props:", this.props.langData.switchToLang);
    if (this.props.langData.switchToLang !== prevProps.langData.switchToLang) {
      this.setState({
        switchToLang: this.props.langData.switchToLang,
      });
    }
  }

  switchLang = () => {
    const switchLang = this.props.switchLang;
    const switchToLang = this.state.switchToLang;
    switchLang(switchToLang);
  }

  render() {
    const header = this.props.langData;

    // console.log(header.switchToLang);
    // console.log(this.state.switchToLang);
    
    return (
      <div className={styles.header}>
        <div className={styles.headerContainer}>
          <div className={styles.headerAbout}>
            <div className={styles.headerAboutPic}><span></span></div>
            <span className={styles.headerAboutName}>{header.name}</span>
          </div>
          <div className={styles.headerLang}>
            <span className={styles.headerLangEng} onClick={this.switchLang}>{header.lang}</span>
          </div>
        </div>
        {/* <div className={styles.headerLine}></div> */}
      </div>
    );
  }
}

export default Header;