import React from 'react';
import styles from './MenuButton.module.css';
import { Link } from 'react-router-dom';


class MenuButton extends React.Component {
  render() {
    const goTo = this.props.goTo;
    const closeMenu = this.props.closeMenu;
    const activeMenu = this.props.activeMenu;
    const buttonText = this.props.buttonText;
    const switchTextBlock = this.props.switchTextBlock;
    
    return (
      <div className={styles.menuButton} onClick={switchTextBlock}  onClick={closeMenu} data-close-menu={true} data-menu-name={activeMenu}>
        <Link className={styles.menuButtonText} to={goTo}>{buttonText}</Link>
      </div>
    );
  }
}

export default MenuButton;